import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Navigate, useNavigate, useRoutes } from "react-router-dom"

// import { ReactComponent as WalletIcon } from "styles/images/menu/Wallet.svg"
// import { ReactComponent as NFTIcon } from "styles/images/menu/NFT.svg"
// import { ReactComponent as HistoryIcon } from "styles/images/menu/History.svg"
import { ReactComponent as SwapIcon } from "styles/images/menu/Swap.svg"
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { ReactComponent as StakeIcon } from "styles/images/menu/Stake.svg"
// import { ReactComponent as GovernanceIcon } from "styles/images/menu/Governance.svg"
// import { ReactComponent as ContractIcon } from "styles/images/menu/Contract.svg"
// import { useIsClassic } from "data/query"

/* menu */
// import Dashboard from "pages/dashboard/Dashboard"
// import Wallet from "pages/wallet/Wallet"
// import NFT from "pages/nft/NFT"
// import History from "pages/history/History"
// import Stake from "pages/stake/Stake"
// import Governance from "pages/gov/Governance"
// import Contract from "pages/contract/Contract"

/* details */
// import ValidatorDetails from "pages/stake/ValidatorDetails"
// import ProposalDetails from "pages/gov/ProposalDetails"

/* txs */
// import SendTx from "txs/send/SendTx"
// import TransferCW721Tx from "txs/wasm/TransferCW721Tx"
import CollatTx from "txs/swap/CollatTx"
import MintTx from "txs/mint/MintTx"
import SwapTx from "txs/stableswap/SwapTx"

// import SwapMultipleTx from "txs/swap/SwapMultipleTx"
// import StakeTx from "txs/stake/StakeTx"
// import WithdrawRewardsTx from "txs/stake/WithdrawRewardsTx"
// import WithdrawCommissionTx from "txs/stake/WithdrawCommissionTx"
// import SubmitProposalTx from "txs/gov/SubmitProposalTx"
// import DepositTx from "txs/gov/DepositTx"
// import VoteTx from "txs/gov/VoteTx"
// import StoreCodeTx from "txs/wasm/StoreCodeTx"
// import InstantiateContractTx from "txs/wasm/InstantiateContractTx"
// import ExecuteContractTx from "txs/wasm/ExecuteContractTx"
// import MigrateContractTx from "txs/wasm/MigrateContractTx"
// import UpdateAdminContractTx from "txs/wasm/UpdateAdminContractTx"
// import AnchorEarnTx from "txs/earn/AnchorEarnTx"
// import SignMultisigTxPage from "pages/multisig/SignMultisigTxPage"
// import PostMultisigTxPage from "pages/multisig/PostMultisigTxPage"

/* auth */
// import Auth from "auth/modules/Auth"
// import ManageNetworksPage from "auth/networks/ManageNetworksPage"
// import AddNetworkPage from "auth/networks/AddNetworkPage"

/* settings */
// import Settings from "pages/Settings"

/* labs */
// import Labs from "pages/labs/Labs"

/* 404 */
import NotFound from "pages/NotFound"
import UnderConstruction from "pages/UnderConstruction"

const ICON_SIZE = { width: 20, height: 20 }

export const useNav = () => {
  const { t } = useTranslation()

  const menu = [
    {
      path: "/collateral",
      element: <CollatTx />,
      title: t("Collateral"),
      icon: <StakeIcon {...ICON_SIZE} />
    },
    {
      path: "/mint",
      element: <MintTx />,
      title: t("StableMint"),
      icon: <AttachMoneyIcon {...ICON_SIZE} />
    },
    // {
    //   path: "/swap",
    //   element: <UnderConstruction when={'Q4 2022'}/>,
    //   title: t("StableSwap"),
    //   icon: <SwapIcon {...ICON_SIZE} />
    // },
    {
      path: "/swap",
      element: <SwapTx />,
      title: t("StableSwap"),
      icon: <SwapIcon {...ICON_SIZE} />
    },
    {
      path: "/lb",
      element: <UnderConstruction when={'Q1 2023'}/>,
      title: t("Lend & Borrow"),
      icon: <AccountBalanceIcon {...ICON_SIZE} />
    },
  ]

  const routes = [
    // { path: "/", element: <Dashboard /> },
    { path: "/", element: <Navigate to={"/collateral"} /> },

    /* pages */
    ...menu,
    // { path: "/validator/:address", element: <ValidatorDetails /> },
    // { path: "/proposal/:id", element: <ProposalDetails /> },

    /* multisig */
    // { path: "/multisig/sign", element: <SignMultisigTxPage /> },
    // { path: "/multisig/post", element: <PostMultisigTxPage /> },

    /* txs */
    // { path: "/send", element: <SendTx /> },
    // { path: "/nft/transfer", element: <TransferCW721Tx /> },
    // { path: "/swap/multiple", element: <SwapMultipleTx /> },
    // { path: "/stake/:address", element: <StakeTx /> },
    // { path: "/rewards", element: <WithdrawRewardsTx /> },
    // { path: "/commission", element: <WithdrawCommissionTx /> },
    // { path: "/proposal/new", element: <SubmitProposalTx /> },
    // { path: "/proposal/:id/deposit", element: <DepositTx /> },
    // { path: "/proposal/:id/vote", element: <VoteTx /> },
    // { path: "/contract/instantiate", element: <InstantiateContractTx /> },
    // { path: "/contract/store", element: <StoreCodeTx /> },
    // { path: "/contract/execute/:contract", element: <ExecuteContractTx /> },
    // { path: "/contract/migrate/:contract", element: <MigrateContractTx /> },
    // { path: "/contract/updateadmin/:contract", element: <UpdateAdminContractTx /> },
    // { path: "/earn", element: <AnchorEarnTx /> },

    /* auth */
    // { path: "/auth/*", element: <Auth /> },
    // { path: "/networks", element: <ManageNetworksPage /> },
    // { path: "/network/new", element: <AddNetworkPage /> },
    // { path: "/settings", element: <Settings /> },

    /* dev */
    // { path: "/labs", element: <Labs /> },

    /* 404 */
    { path: "*", element: <NotFound /> },
  ]

  return { menu, element: useRoutes(routes) }
}


/* export const useNav = () => {
  const { t } = useTranslation()
  const isClassic = useIsClassic()

  const menu = [
    {
      path: "/wallet",
      element: <Wallet />,
      title: t("Wallet"),
      icon: <WalletIcon {...ICON_SIZE} />,
    },
    {
      path: "/history",
      element: <History />,
      title: t("History"),
      icon: <HistoryIcon {...ICON_SIZE} />,
    },
    {
      path: "/swap",
      element: <SwapTx />,
      title: t("Swap"),
      icon: <SwapIcon {...ICON_SIZE} />,
      classic: true,
    },
    {
      path: "/stake",
      element: <Stake />,
      title: t("Stake"),
      icon: <StakeIcon {...ICON_SIZE} />,
    },
    {
      path: "/gov",
      element: <Governance />,
      title: t("Governance"),
      icon: <GovernanceIcon {...ICON_SIZE} />,
    },
    {
      path: "/nft",
      element: <NFT />,
      title: t("NFT"),
      icon: <NFTIcon {...ICON_SIZE} />,
    },
    {
      path: "/contract",
      element: <Contract />,
      title: t("Contract"),
      icon: <ContractIcon {...ICON_SIZE} />,
    },
  ].filter(({ classic }) => {
    if (isClassic) return true
    return !classic
  })

  const routes = [
    { path: "/", element: <Dashboard /> },

    // pages
    ...menu,
    { path: "/validator/:address", element: <ValidatorDetails /> },
    { path: "/proposal/:id", element: <ProposalDetails /> },

    // multisig
    { path: "/multisig/sign", element: <SignMultisigTxPage /> },
    { path: "/multisig/post", element: <PostMultisigTxPage /> },

    // txs 
    { path: "/send", element: <SendTx /> },
    { path: "/nft/transfer", element: <TransferCW721Tx /> },
    { path: "/swap/multiple", element: <SwapMultipleTx /> },
    { path: "/stake/:address", element: <StakeTx /> },
    { path: "/rewards", element: <WithdrawRewardsTx /> },
    { path: "/commission", element: <WithdrawCommissionTx /> },
    { path: "/proposal/new", element: <SubmitProposalTx /> },
    { path: "/proposal/:id/deposit", element: <DepositTx /> },
    { path: "/proposal/:id/vote", element: <VoteTx /> },
    { path: "/contract/instantiate", element: <InstantiateContractTx /> },
    { path: "/contract/store", element: <StoreCodeTx /> },
    { path: "/contract/execute/:contract", element: <ExecuteContractTx /> },
    { path: "/contract/migrate/:contract", element: <MigrateContractTx /> },
    {
      path: "/contract/updateadmin/:contract",
      element: <UpdateAdminContractTx />,
    },
    { path: "/earn", element: <AnchorEarnTx /> },

    // auth
    { path: "/auth/*", element: <Auth /> },
    { path: "/networks", element: <ManageNetworksPage /> },
    { path: "/network/new", element: <AddNetworkPage /> },
    { path: "/settings", element: <Settings /> },

    // dev
    { path: "/labs", element: <Labs /> },

    // 404 
    { path: "*", element: <NotFound /> },
  ]

  return { menu, element: useRoutes(routes) }
} */

/* helpers */
export const useGoBackOnError = ({ error }: QueryState) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (error) navigate("..", { replace: true })
  }, [error, navigate])
}
